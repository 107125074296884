import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { TFunction } from 'next-i18next';

import { POST_LIST_QUERY } from 'schema/Post/queries';
import {
  POSTS_PER_PAGE_ON_HOME,
  POSTS_PER_PAGE,
  SORT_BY_TO_POST_ORDER_FIELDS,
  NUMBER_OF_REACTIONS,
  SORT_BY_PARAMS,
  TRENDING_POSTS_MAX,
} from 'utils/constants';
import { SkeletonPostContainer } from 'koba/components/SkeletonPost/SkeletonPost';
import PostListSort from 'components/PostListSort';
import Button from 'koba/components/Button';
import { useCommunityContext } from 'components/CommunityContext';
import { PageInfo } from 'interfaces/pageInfo';
import { pathnames } from 'utils/routerHelpers';
import { replaceMentions } from 'utils/mentions';
import { PostOrderField } from '__generated__/graphql/legacy/graphql';

import PostContainer from 'components/PostContainer/PostContainer';
import styles from './PostList.module.scss';

const cx = classNames.bind(styles);

interface PostListProps {
  customEmptyState?: ReactNode;
  emptyTitle: string;
  emptyMessage?: string;
  filterByFollowing?: boolean;
  isTrending?: boolean;
  t: TFunction;
  shouldShowTagOnPost?: boolean;
  sharedState: {
    useCacheFirst: boolean;
  };
}

export const PostList: React.FC<PostListProps> = ({
  t,
  customEmptyState,
  shouldShowTagOnPost,
  emptyTitle,
  emptyMessage,
  isTrending = false,
  filterByFollowing = false,
  sharedState,
}) => {
  const fetchPolicy = sharedState.useCacheFirst
    ? 'cache-first'
    : 'cache-and-network';
  if (sharedState.useCacheFirst) {
    // If another page has set the useCacheFirst flag, that means it wants to preserve the current Post List.
    // This is useful if a user has clicked Load More, clicked on a post, and wants to return to the same scroll
    // location. Is it hacky? Yes. Can we do better? One day I hope.
    // eslint-disable-next-line no-param-reassign
    sharedState.useCacheFirst = false;
  }

  const router = useRouter();

  const { community } = useCommunityContext();

  const { spaceId, sortBy } = router.query;
  const sortByKey = Array.isArray(sortBy) ? sortBy[0] : sortBy;

  let postOrderField =
    shouldShowTagOnPost && !filterByFollowing
      ? PostOrderField.CreatedAt
      : SORT_BY_TO_POST_ORDER_FIELDS[sortByKey || SORT_BY_PARAMS.RECENT];

  let numOfPostsPerPage =
    shouldShowTagOnPost && !filterByFollowing
      ? POSTS_PER_PAGE_ON_HOME
      : POSTS_PER_PAGE;

  // trending posts
  postOrderField = isTrending ? PostOrderField.TrendingScore : postOrderField;
  numOfPostsPerPage = isTrending ? TRENDING_POSTS_MAX : numOfPostsPerPage;

  const defaultEmptyState = (
    <div
      className={cx('spaces-enabled-empty-state', {
        'is-home': router.pathname === pathnames.home,
      })}
    >
      <span>{emptyTitle}</span>
      <p>{emptyMessage}</p>
    </div>
  );

  const { loading, data, refetch, previousData, fetchMore } = useQuery(
    POST_LIST_QUERY,
    {
      fetchPolicy,
      // When this prop is specied, fetchPolicy is used for the query's first execution,
      // and nextFetchPolicy is used to determine how the query responds to future cache updates.
      // Here is a more detailed explanation of why do we need this from now on
      // https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      variables: {
        communityId: community.id,
        numOfPosts: numOfPostsPerPage,
        postOrderField,
        filter: {
          following: filterByFollowing,
          communitySpaceId: spaceId,
          trending: isTrending,
        },
        numberOfReactions: NUMBER_OF_REACTIONS,
      },
    }
  );

  const posts =
    data?.community?.posts?.edges ||
    previousData?.community?.posts?.edges ||
    [];

  const pageInfo: PageInfo =
    data?.community?.posts?.pageInfo ||
    previousData?.community?.posts?.pageInfo;

  const onLoadMorePosts = async () => {
    await fetchMore({ variables: { endCursor: pageInfo.endCursor } });
  };

  if (!data && !previousData && loading) {
    // If we haven't completed our initial load, show the skeleton
    return <SkeletonPostContainer />;
  }

  if (posts.length === 0) {
    return customEmptyState ? (
      <>{customEmptyState}</>
    ) : (
      <>{defaultEmptyState}</>
    );
  }

  return (
    <>
      {(!shouldShowTagOnPost || filterByFollowing) && <PostListSort />}
      {posts.map(({ node }) => {
        const content = replaceMentions(node?.content, node?.mentionedUsers);

        return (
          <PostContainer
            author={node.user}
            content={content}
            key={node.id}
            mentionedUsers={node?.mentionedUsers}
            post={node}
            refetchPostList={refetch}
            shouldShowTagOnPost={shouldShowTagOnPost}
            t={t}
          />
        );
      })}
      {pageInfo?.hasNextPage && !isTrending && (
        // @ts-ignore
        <Button
          appearance="ghost"
          className={cx('load-more')}
          data-qa={cx('load-more')}
          onClick={onLoadMorePosts}
        >
          {shouldShowTagOnPost ? t('See More') : t('Load More')}
        </Button>
      )}
    </>
  );
};

export default PostList;

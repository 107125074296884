import React, { KeyboardEvent, MouseEvent } from 'react';
import { TFunction } from 'next-i18next';
import { SORT_BY_PARAMS } from 'utils/constants';
import { Item, Menu } from 'koba/components/Dropdown';
import classNames from 'classnames/bind';
import Button from 'koba/components/Button';
import { sortByTitle } from './helpers';

import styles from './PostListSort.module.scss';

const cx = classNames.bind(styles);

interface DesktopDropdownMenuProps {
  handleChange(
    param: string,
    toggleMenu: (e: Event) => void
  ): (e: MouseEvent | KeyboardEvent) => void;
  isOpen: boolean;
  t: TFunction;
  toggleMenu(e: Event): void;
}

const DesktopDropdownMenu: React.FC<DesktopDropdownMenuProps> = ({
  isOpen,
  t,
  toggleMenu,
  handleChange,
}) => (
  <Menu open={isOpen} position="left">
    {Object.values(SORT_BY_PARAMS).map((sortByParam) => (
      <Item key={sortByParam} title={sortByTitle(sortByParam, t)}>
        <Button
          appearance="ghost"
          className={cx('post-list-sort__button')}
          size="small"
          onClick={handleChange(sortByParam, toggleMenu)}
        >
          {sortByTitle(sortByParam, t)}
        </Button>
      </Item>
    ))}
  </Menu>
);

export default DesktopDropdownMenu;

import React from 'react';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';

import { ButtonWithIconRight } from 'koba/components/Button';
import { Caption } from 'koba/components/Typography';
import Icon from 'koba/components/Icon';
import { Dropdown } from 'koba/components/Dropdown';
import { useTranslation } from 'react-i18next';

import { pushRouteWithSortBy } from 'utils/routerHelpers';
import { BREAKPOINT_MD } from 'utils/constants';
import { useWindowSize } from 'hooks/useWindowSize';
import { sortByTitle } from './helpers';
import DesktopDropdownMenu from './DesktopDropdownMenu';
import MobileModalMenu from './MobileModalMenu';

import styles from './PostListSort.module.scss';

const cx = classNames.bind(styles);

export const PostListSort: React.FC = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isMobile = width < BREAKPOINT_MD;
  const sortByFieldFromParams = Array.isArray(router.query.sortBy)
    ? router.query.sortBy[0]
    : router.query.sortBy || '';

  const handleChange = (sortBy, toggleMenu) => () => {
    pushRouteWithSortBy(router, sortBy);
    toggleMenu();
  };

  return (
    <div className={cx('post-list-sort__container')}>
      <Caption className={cx('post-list-sort__caption', 'is-spaces-enabled')}>
        {t('Sort posts by')}
      </Caption>
      <Dropdown className={cx('post-list-sort__dropdown')}>
        {({ open, toggleMenu }) => (
          <>
            <ButtonWithIconRight
              appearance="ghost"
              className={cx('post-list-sort__toggle', 'is-spaces-enabled')}
              size="small"
              onClick={toggleMenu}
            >
              {sortByTitle(sortByFieldFromParams, t)}
              {open ? (
                <Icon ariaLabel={t('Close menu')} name="caret-fill-up" />
              ) : (
                <Icon ariaLabel={t('Open menu')} name="caret-fill-down" />
              )}
            </ButtonWithIconRight>
            {isMobile ? (
              <MobileModalMenu
                handleChange={handleChange}
                isOpen={open}
                t={t}
                toggleMenu={toggleMenu}
              />
            ) : (
              <DesktopDropdownMenu
                handleChange={handleChange}
                isOpen={open}
                t={t}
                toggleMenu={toggleMenu}
              />
            )}
          </>
        )}
      </Dropdown>
    </div>
  );
};

export default PostListSort;

import React, { KeyboardEvent, MouseEvent } from 'react';
import { TFunction } from 'next-i18next';
import { ActionModal, ActionModalItem } from '../ActionModal';
import { SORT_BY_PARAMS } from '../../utils/constants';
import { sortByTitle } from './helpers';

interface MobileModalMenuProps {
  handleChange(
    param: string,
    toggleMenu: (e: MouseEvent | KeyboardEvent) => void
  ): (e: MouseEvent | KeyboardEvent) => void;
  isOpen: boolean;
  t: TFunction;
  toggleMenu(e: MouseEvent | KeyboardEvent): void;
}

const MobileModalMenu: React.FC<MobileModalMenuProps> = ({
  handleChange,
  isOpen,
  t,
  toggleMenu,
}) => (
  <ActionModal closeModal={toggleMenu} isOpen={isOpen} t={t}>
    {Object.values(SORT_BY_PARAMS).map((sortByParam) => (
      <ActionModalItem
        key={sortByParam}
        title={sortByTitle(sortByParam, t)}
        onClick={handleChange(sortByParam, toggleMenu)}
      >
        {sortByTitle(sortByParam, t)}
      </ActionModalItem>
    ))}
  </ActionModal>
);

export default MobileModalMenu;

import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { Row } from '@thinkific/toga-react';
import { Col } from 'toga';

import Image from 'components/Image';
import InfoCard from 'components/InfoCard';
import VisibilityCloak from 'components/VisibilityCloak/VisibilityCloak';
import AdminOnlyIndicator from 'components/AdminOnlyIndicator';
import styles from './EmptyStateCard.module.scss';

const cx = classNames.bind(styles);

interface EmptyStateCardProps {
  title: string;
  content: ReactNode;
  imageSrc: string;
  dataQA?: string;
  emptyStateAction?: ReactNode;
  hasAdminSection?: boolean;
}

const EmptyStateCard: React.FC<EmptyStateCardProps> = ({
  title,
  content,
  dataQA,
  imageSrc,
  emptyStateAction,
  hasAdminSection = false,
}) => {
  return (
    <div className={cx('empty-state-card')}>
      <InfoCard dataQA={dataQA} title={title}>
        <Row>
          <Col lgColumns={8}>
            <div>{content}</div>
            {emptyStateAction && (
              <div className={cx('empty-state-card__buttons')}>
                {emptyStateAction}
              </div>
            )}
          </Col>
          <Col lgColumns={4}>
            <VisibilityCloak hideMD>
              <Image height={155} src={imageSrc} width={217} />
            </VisibilityCloak>
          </Col>
        </Row>
        {hasAdminSection && (
          <Row>
            <Col smColumns={12}>
              <VisibilityCloak admins>
                <AdminOnlyIndicator />
              </VisibilityCloak>
            </Col>
          </Row>
        )}
      </InfoCard>
    </div>
  );
};

export default EmptyStateCard;

import { TFunction } from 'next-i18next';
import { SORT_BY_PARAMS } from 'utils/constants';

export const sortByTitle = (sortByParam: string, t: TFunction): string => {
  const { RECENT, RECENT_COMMENTS, MOST_COMMENTS } = SORT_BY_PARAMS;

  switch (sortByParam) {
    case RECENT:
      return t('Most recent');
    case RECENT_COMMENTS:
      return t('New comments');
    case MOST_COMMENTS:
      return t('Most comments');
    default:
      return t('Most recent');
  }
};
